@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body {
	color: #000;
	font-size: 16px;
	// font-family: 'Open Sans', sans-serif;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
}

// body                                 { background: url(/i/design/design.jpg) center top no-repeat; opacity: .5; }

// font-family: 'Open Sans', sans-serif;

// LINKS
body a {
	color: #1c87f3;
	text-decoration: none;
	transition: 1s;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	font-family: 'Source Sans Pro', sans-serif;
}

h1 {
	font-size: 38px;
	margin: 0 0 20px 0;
	color: #1c87f3;
	line-height: 1.2;
}

h2 {
	font-size: 20px;
	margin: 25px 0 20px 0;
	color: #99cc33;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 10px 0;
	padding: 5px 10px;
	background: #1c87f3;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: 1s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}


// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 1200px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	height: 160px;
	background: #fff;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 1360px;

	@media only screen and (max-width: 1650px) {
		width: 95%;
	}
}

header h1 {
	margin: 0;
	background: none;

	@media only screen and (max-width: 620px) {
		margin: 100px 0 0 0;
	}
}

#logo-index {
	margin: 0;
	padding: 0;
}

img#logo {
	float: left;
	margin: 10px 0 0 0;
	max-width: 200px;
}

.social-dock {
	position: absolute;
	right: 117px;
	top: 20px;
	display: flex;
	gap: 50px;
}

.twitter-header {
	height: 24px;
	width: 24px;
	background: url(/i/design/twitter-ic.png) no-repeat;

}

.trust-logo {
	position: absolute;
	right: 0px;
	top: 10px;

	a {
		display: block;
		height: 100%;
	}

	@media only screen and (max-width: 1650px) {
		img {
			width: 150px;
		}
	}

	@media only screen and (max-width: 1400px) {
		// img {
		// 	width: 100px;
		// }
	}

	@media only screen and (max-width: 1200px) {
		top: 50px;
		width: 100px;
	}

	@media only screen and (max-width: 620px) {
		right: 0;
	}
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	z-index: 9999;
	font-size: 16px;

}

#FS input {
	outline: none;
	color: #fff;
}

#FS input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {
	display: none;
}

#FS input[type=search] {
	color: transparent;
	cursor: pointer;
	background: url(/i/design/search-ic.png) no-repeat 0 center;
	padding: 0px;
	width: 24px;
	height: 23px;
	transition: all .5s;
}

#FS input[type=search]:focus {
	width: 200px;
	background: #000;
	padding: 5px 0 5px 12px;
	color: #fff;
	cursor: auto;
	font-weight: 400;
}

#FS input[type=search]:focus:-moz-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input[type=search]:focus::-webkit-input-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input:-moz-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input::-webkit-input-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}


// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #1c87f3;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

.goog-te-gadget .goog-te-combo {
	margin: 0px !important;
	width: 24px;
	height: 26px;

}

#google_translate_element {
	opacity: 0;
}

.translate {
	overflow: hidden;
	width: 24px;
	height: 26px;
	background: url(/i/design/translate-ic.png) no-repeat;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_4742 #mainholder {
	width: 100%;
	padding: 0;
}

#mainholder {
	width: 990px;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}


// -----------------------------------//
// WELCOME
// -----------------------------------//


#welcome {
	min-height: 720px;
	padding: 0 0 30px 0;
	background: #1c87f3;
	width: 100vw;
	position: relative;
	left: 0;

	.wrapper {
		overflow: hidden;
		text-align: center;
	}

	h2 {
		color: #fff;
		font-size: 48px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 35px 0 20px 0;
		line-height: 1.2;

		span {
			display: block;
			font-size: 30px;
			font-weight: 400;
		}

		em {
			color: #ffe1aa;
			font-size: 18px;
			text-transform: none;
			font-weight: 700;
			display: block;
			font-style: normal;
			margin: 15px 0 60px 0;
			line-height: normal;
		}
	}

	p {
		font-size: 18px;
		color: #fff;
		line-height: 32px;
		font-weight: 300;
		margin: 15px 0;
	}

	p.outro {
		margin: 80px 0 0 0;
		min-height: 140px;

		img {
			padding: 0 5px;
			max-height: 120px;
			max-width: 120px;
			border-radius: 50%;
			margin: 0 0 -20px 0;
		}

		span {
			font-size: 18px;
			color: #ffe1aa;
			position: relative;
			top: -38px;
			left: 40px;

			@media only screen and (max-width: 1650px) {
				top: auto;
				left: auto;
			}
		}
	}

	.read-more {
		margin: 60px 0 0 0;

		a {
			color: #ffe1aa;
			font-weight: 700;
			letter-spacing: 1px;
		}
	}
}

.welcome {}



// -----------------------------------//
// MISCELLANEOUS
// -----------------------------------//

#home-boxes {
	width: 100%;
	height: 1100px;
	background: #fff url(/i/design/boxes-bg.jpg) no-repeat right bottom;
	overflow: hidden;
	position: relative;
	padding: 50px 0;

	.btn-wrapper {
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		max-width: 1800px;
		justify-content: center;

		@media only screen and (max-width: 990px) {}
	}

}



.box-1 {
	width: 30%;
	height: 515px;
	display: inline-block;
	margin: auto;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);
	position: relative;
	background: #FFF;
	transition: 1s;


	&:hover {
		background: #99cc33;
		transition: 1s;

		p {
			color: #fff;
			transition: 1s;
		}

		a {
			color: #fff;

		}
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 290px;
		object-fit: cover;
	}

	h2 {
		position: absolute;
		width: 80%;
		height: 60px;
		line-height: 60px;
		background: #99cc33;
		color: #fff;
		text-align: center;
		color: #fff;
		margin: auto;
		left: 0;
		right: 0;
		top: 250px;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
	}

	hr {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		margin: auto;
		background: url(/i/design/hr-bg.jpg) no-repeat center center;
		height: 2px;
	}

	p {
		position: absolute;
		top: 330px;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 90%;
		color: #000;
		font-size: 18px;
		font-weight: 500;
		transition: 1s;
	}

	a {
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: auto;
		color: #99cc33;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}

}


.box-2 {
	width: 30%;
	height: 515px;
	display: inline-block;
	margin: auto;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);
	position: relative;
	margin: 0 50px;
	background: #FFF;
	transition: 1s;

	&:hover {
		background: #1c87f3;
		transition: 1s;

		p {
			color: #fff;
			transition: 1s;
		}

		a {
			color: #fff;
		}
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 290px;
		object-fit: cover;
	}

	h2 {
		position: absolute;
		width: 80%;
		height: 60px;
		line-height: 60px;
		background: #1c87f3;
		color: #fff;
		text-align: center;
		color: #fff;
		margin: auto;
		left: 0;
		right: 0;
		top: 250px;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
	}

	hr {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		margin: auto;
		background: url(/i/design/hr-bg.jpg) no-repeat center center;
		height: 2px;
	}

	p {
		position: absolute;
		top: 330px;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 90%;
		color: #000;
		font-size: 18px;
		font-weight: 500;
	}

	a {
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: auto;
		color: #1c87f3;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}

}


.box-3 {
	width: 30%;
	height: 515px;
	display: inline-block;
	margin: auto;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);
	position: relative;
	background: #FFF;
	transition: 1s;

	&:hover {
		background: #ffa400;
		transition: 1s;

		p {
			color: #fff;
			transition: 1s;
		}

		a {
			color: #fff;
		}
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 290px;
		object-fit: cover;
	}

	h2 {
		position: absolute;
		width: 80%;
		height: 60px;
		line-height: 60px;
		background: #ffa400;
		color: #fff;
		text-align: center;
		color: #fff;
		margin: auto;
		left: 0;
		right: 0;
		top: 250px;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
	}

	hr {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		margin: auto;
		background: url(/i/design/hr-bg.jpg) no-repeat center center;
		height: 2px;
	}

	p {
		position: absolute;
		top: 330px;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 90%;
		color: #000;
		font-size: 18px;
		font-weight: 500;
	}

	a {
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: auto;
		color: #ffa400;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}

}

.box-1,
.box-2,
.box-3 {
	margin-bottom: 80px;

	@media only screen and (max-width: 990px) {
		margin: 0 10px 60px !important;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		text-transform: uppercase;
		font-weight: 700;
	}

	h2 {
		font-weight: 700;
	}
}

//Content 2
.Content2Column {
	width: 650px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		text-transform: uppercase;
		font-weight: 700;
	}

	h2 {
		font-weight: 700;
	}

	tr,
	td {
		border: 1px solid #000 !important;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	display: block;
	text-align: center;
	font-size: 24px;
	padding: 20px 0;
	margin: 0;
}

// List
ul.SideList {
	margin: 0 0 0 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0 0 0 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 5px 10px;
	color: #333;
	transition: 1s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	background: #999;
	color: #FFF;
	transition: 1s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;

	&:hover {
		background-color: #999;
	}
}


// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	width: 55px;
	height: 55px;
	text-align: center;
	background: #000;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 130%;

	span {
		display: block;
		font-weight: bold;
		font-size: 24px;
		margin: 10px 0 0 0;
	}
}

ul.SideList p.title {
	float: right;
	width: 75%;
	font-size: 15px;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 15px;
	padding: 0px;
	transition: 1s;

	&:hover {
		transition: 1s;
		text-decoration: none;
	}
}

// -----------------------------------//
// SCHOOL FEEDS
// -----------------------------------//

//Diary
#SideHeadingDiary {}

//News
#SideHeadingNews {}

//Related pages
#SideHeadingPages {
	overflow: hidden;
	background: #1c87f3;
	box-shadow: 0px 16px 16px 0px rgba(9, 54, 37, 0.21);

	h2 {
		color: #fff;
		font-weight: 700;
		font-size: 36px;
	}

	ul.SideList {
		padding: 20px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 16px;
				color: #fff;

				&:hover {
					background-color: #fff;
					color: #96c22f;
				}
			}
		}
	}

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}


// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 13px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}


// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	height: 433px;
	overflow: hidden;
	background: #efefef;
	font-family: 'Source Sans Pro', sans-serif;
}

footer>.wrapper {
	color: #000;
	text-align: left;
	position: relative;
	overflow: hidden;

	@media only screen and (max-width: 1400px) {
		width: 97%;
	}

	h1 {
		color: #99cc33;
		font-size: 48px;
		margin: 0;
		position: absolute;
		left: 0;
		top: 50px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.brunel-trust-footer {
		position: absolute;
		right: 80px;
		top: 40px;

		@media only screen and (max-width: 1400px) {
			display: none;
		}
	}
}

footer>.wrapper p {
	margin: 0;
	letter-spacing: 0.02em;
}

footer>.wrapper a {}

.disability-confident {
	position: absolute;
	right: 0;
	top: 145px;
	height: 68px;
	width: 150px;
	background: url(/i/design/disability-bg.png) no-repeat;
}

.address-1 {
	position: relative;
	left: 0;
	top: 120px;
	// width: 265px;

	h2 {
		color: #000;
		font-size: 28px;
		text-transform: uppercase;
		margin: 0 0 30px 0;
	}

	p.address {
		margin: 0 0 0 0;
		font-size: 24px;
		font-weight: 400;
		color: #000;
		line-height: 1.3;
	}

	a {
		color: #1c87f3;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 30px 0 0 0;
		display: block;

		&:nth-of-type(1) {
			display: inline;
			text-transform: none;
			font-weight: normal;
			font-size: 22px;
		}
	}
}

.scroll-up {
	position: absolute;
	right: 0;
	top: 0;
	width: 55px;
	height: 55px;
	background: url(/i/design/scroll-up.png) no-repeat;
}

p.copyright {
	position: absolute;
	right: 80px;
	bottom: 35px;
	color: #99cc33;
	font-size: 14px;
	font-weight: 800;
	text-align: right;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	line-height: 2.7;

	a {
		color: #99cc33;

		&:nth-of-type(1) {
			margin: 0 40px 0 0;
		}
	}

	img {
		padding-left: 10px;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 30px;
	width: 100%;
	line-height: 30px;
	background: #666;
	overflow: hidden;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #ff0000;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	font-family: 'Open Sans', sans-serif;
	text-align: center;
}

$blockquote-background-color: #EFEFEF;
$blockquote-border-color: #99cc33;
$blockquote-color: #000;
$blockquote-speech-mark-color: #99cc33;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}