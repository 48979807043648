//iPad basic
@media only screen and (max-width: 1400px) {


	.wrapper {
		width: 100%;
	}

	header {
		.wrapper {
			width: 100%;
			background: none;
		}
	}

	//Search Form
	#FS {
		display: none;
	}

	.translate {
		display: none;
	}

	.youtube-header {
		display: none;
	}

	.twitter-header {
		display: none;
	}

	nav#navholder {
		right: 0;
	}

	.box-1 hr {
		display: none;
	}

	.box-2 hr {
		display: none;
	}

	.box-3 hr {
		display: none;
	}

}

// Mobile Version

@media only screen and (max-width: 1200px) {

	// Body

	body {
		min-width: 0;
		overflow-x: hidden;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder {
		width: 100%;
		overflow: scroll;
		white-space: nowrap;
		table-layout: fixed;
		position: relative;

		&:before {
			content: "";
			width: 30px;
			height: 100%;
			position: absolute;
			top: 0;
			right: -1px;
			background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#33000000', GradientType=1);
		}

		tbody {
			width: 100%;
			float: left;
			white-space: normal;
			overflow-x: scroll;
		}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar

	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		h2 {}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}



	// Header
	header {
		height: auto;
		overflow: hidden;

		.wrapper {
			padding: 0;
		}
	}


	img#logo {
		margin: 60px auto 20px;
		float: none;
		display: block;
		width: 85%;
		max-width: 200px;
	}

	//Slider
	#slider {}

	.tagline {

		h2 {
			height: auto;
			font-size: 30px;
			box-shadow: none;
			padding: 10px 0;
		}
	}

	#welcome {
		width: 80%;
		padding: 5%;
		margin: 5%;

		img {
			display: none;
		}
	}

	#home-boxes {
		height: auto;
	}

	.box-1 {
		margin: 5px auto;
		max-width: 300px;
		width: 100%;

		h2 {
			font-size: 20px;
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		p {
			font-size: 16px;
		}
	}

	.box-2 {
		margin: 5px 20px;
		max-width: 300px;
		width: 100%;

		h2 {
			font-size: 20px;
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		p {
			font-size: 16px;
		}
	}

	.box-3 {
		margin: 5px auto;
		max-width: 300px;
		width: 100%;

		h2 {
			font-size: 20px;
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		p {
			font-size: 16px;
		}
	}

	// Mainholder
	#mainholder {
		width: 100%;
		padding: 0;
	}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	//Home page alert
	.modal-home {
		.message-modal {
			width: 90%;
			margin: 0 5%;
			left: 0;
			top: 20%;
			padding: 0;

			.modal-content {
				.modal-text {
					padding: 3%;
				}

				a.close-modal {
					position: absolute;
					top: 3%;
					right: 3%;
					background: #fff;
					padding: 3px;
					border-radius: 0px 0px 0px 7px;
				}
			}
		}
	}

	// Footer
	footer {
		height: auto;

		.wrapper {
			width: 90%;
			padding: 5%;

			h1 {
				position: inherit;
				left: 0;
				right: 0;
				top: 0;
				margin: 15px auto;
				text-align: center;
				bottom: 0;
			}

			p {
				text-align: center;
				margin: 10px auto;
				display: block;
				position: inherit;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
			}

			a {
				word-break: break-all;
			}
		}
	}

	.address-1 {
		position: inherit;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;

		h2 {
			text-align: center;
		}
	}

	.address-2 {
		position: inherit;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;

		h2 {
			text-align: center;
		}
	}

	.scroll-up {
		display: none;
	}

	.disability-confident {
		display: none;
	}

	#awards {
		display: none;
	}

	.sub-footer {
		height: auto;
		line-height: normal;
		padding: 5px 0;

		p {
			text-align: center;
			margin: 5px auto;
			float: none;
		}

		#credit {
			float: none;
		}
	}
}


@media only screen and (max-width: 700px) {

	.box-1 {
		display: block;
	}

	.box-2 {
		display: block;
		margin: 5px auto;
	}

	.box-3 {
		display: block;
	}
}